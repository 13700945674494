import React from "react"
import { graphql } from "gatsby"
import AOS from "aos"
import SEO from "../components/seo"
import HeroSmall from "../components/hero--sm"
import CTA from "../components/cta"
import ArticleCard from "../components/article-card"

class Blog extends React.Component {

  state = {
    posts: [],
  }

  componentDidMount() {
    this.setState({
      posts: this.props.data.allMarkdownRemark.edges,
    })

		AOS.init({
			duration: 800,
			easing: 'easeOutCubic',
      anchorPlacement: 'top-bottom'
		});
  }

  componentDidUpdate() {
    var secondArticle = document.querySelector('.latest-articles__cards .card-wrapper:nth-child(even)');
    if (secondArticle)
      secondArticle.setAttribute('data-aos-delay', '300');

    AOS.refresh();
  }

  searchPosts = (e) => {
    let currentItems = []
    let searchingItem = []
    if (e.target.value !== '') {
      currentItems = this.props.data.allMarkdownRemark.edges
      searchingItem = currentItems.filter(({ node }) => {
        
      })
    } else {
      searchingItem = this.props.data.allMarkdownRemark.edges
    }
    this.setState({
      posts: searchingItem,
    })
  }

  render() {
    return(
      <div>
        	<SEO title="Blog" description="Here you'll find some of my thoughts, how-to's and opinion pieces. They're not perfect, but every now and then someone might find them useful or interesting, so enjoy!" />
  		    <HeroSmall heading="💡 Blog"/>
          <section className="latest-articles">
            <div className="container">
              <div className="latest-articles__heading">
                <h2 data-aos="fade-right">
                Ramblings and musings of an average developer
                <small>(latest posts)</small>
                </h2>
                <p data-aos="fade-right" data-aos-delay="300">Here you'll find some of my thoughts, how-to's and opinion pieces. They're not perfect, but every now and then someone might find them useful or interesting, so enjoy!</p>
              </div>
              <div className="latest-articles__cards">
                {this.state.posts.map(({ node }, index) => (
                  <ArticleCard 
                    title={node.frontmatter.fancyTitle ? node.frontmatter.fancyTitle : node.frontmatter.title}
                    slug={'/blog' + node.fields.slug}
                    image={node.frontmatter.image}
                    date={node.frontmatter.date}
                    readTime={node.frontmatter.readTime}
                    key={index}
                    index={index}
                    tags={node.frontmatter.tags}
                  />
                ))}
              </div>
            </div>
          </section>
          <CTA/>
  	  </div>
    )
  }
}

export default Blog

export const BlogQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            fancyTitle
            image
            readTime
            tags
          }
        }
      }
    }
  }
`