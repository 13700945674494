
import React from "react"
import { Link } from "gatsby"

export default function ArticleCard(props) {

  return (
    <article className="card-wrapper" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-index={props.index}>
      <div className="card">
        {!!props.image && (
          <img className="card__image" src={props.image} alt={props.title + "- Featured Image"} />
        )}
        <div className="card__inner">
          <h3 className="h4 card__heading">{props.title}</h3>
          <div className="card__meta">
            <span><span role="img" aria-label="book">📖</span> {props.readTime}</span> 
            <span><span role="img" aria-label="calendar">🗓</span> {props.date}</span>
          </div>
          <ul className="tags">
          {props.tags.map((tag) => (
            <li className="tag" key={tag + `tag`}>{tag}</li>
          ))}
          </ul>
          <Link to={props.slug}>Read the article titled: {props.title}</Link>
        </div>
      </div>
    </article>
  )
}
