import React from "react"
import passport from "../images/passport-2.svg"

export default function HeroSmall(props) {

  	return (
	  	<section className={'hero hero--sm ' + props.extraClass}>
		  	<div className="container">
		  		<div className="hero__left animate--fadeUp">
		  			<img className="passport" src={passport} alt=""/>
		  		</div>
		  		<div className="hero__right">
		  			<div className="hero__right__copy">
		  				<h1 className="h2">{props.heading}</h1>
		  			</div>
		  		</div>
		  	</div>
	  	</section>
  	)
}