import React from "react"
import Link from "gatsby-link"
import cv from "../files/jasonclixby-cv-21.pdf"

export default function CTA(props) {

  	return (
	  	<section className="cta">
		  	<div className="container">
		  		<div className="cta__left">
		  			<h2 className="cta__heading" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">If you want to see my journey so far, check out my paper trail</h2>
		  		</div>
		  		<div className="cta__right" data-aos="fade-left" data-aos-delay="500" data-aos-anchor-placement="bottom-bottom">
		  			<a href={cv} target="_blank" className="btn white">View/Download CV</a>
		  		</div>
		  	</div>
	  	</section>
  	)
}